import React from 'react';
import { useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import './Styles/TopBanner.css';

function TopBanner() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x) as Array<keyof typeof journeyPaths>;

  const journeyPaths = {
    orderlist: "Order List",
    order: "Order",
    summary: "Summary",
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all items in localStorage
  };

  return (
    <div className="flex flex-col p-4 bg-gray-100">
      <div className="flex items-center mb-4">
        <img src={require('./logo.png')} alt="Logo" className="mr-4" />
        <div className="text-3xl font-bold">
          Welcome to the Xceed Solutions ordering platform
        </div>
      </div>
      
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2 text-blue-500">
          {pathnames.length > 0 && (
            <>
              <a href={process.env.REACT_APP_PublicPath + "/orderlist"} className="hover:underline">
                Order History 
              </a>
              {pathnames.map((value, index) => {
                const routeTo = `/${value}`;
                const isLast = index === pathnames.length - 1;
                return isLast ? (
                  <span key={index} className="font-bold flex items-center">
                    <ChevronRightIcon className="h-5 w-5 inline-block" />
                    {journeyPaths[value]}
                  </span>
                ) : (
                  <span key={index} className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5" />
                    <a href={process.env.REACT_APP_PublicPath + routeTo} className="hover:underline">
                      {journeyPaths[value]}
                    </a>
                  </span>
                );
              })}
            </>
          )}
        </div>
        <a 
          href={process.env.REACT_APP_PublicPath + "/"} 
          className="text-blue-500 hover:underline"
          onClick={handleLogout}
        >
          Logout
        </a>
      </div>
    </div>
  );
}

export default TopBanner;
