
import React, { useState, useEffect, useRef,useLayoutEffect } from 'react'
import useFontFaceObserver from 'use-font-face-observer';

const BannerSample = (props: any) => {
    let myself = useRef(null);
    const [myFontSizeLarge, setMyFontSizeLarge] = useState(200);
    const [myFontSizeSmall, setMyFontSizeSmall] = useState(8);
    const [directStyle, setDirectStyle] = useState()
    const [myHeight, setMyHeight] = useState(600)
    const [actualHeightLarge, setActualHeightLarge] =  useState(546)
    const [heightLimitLarge, setHeightLimitLarge] = useState(546)
    const [heightLimitSmall, setHeightLimitSmall] = useState(36)
    const [marginTop, setMarginTop] = useState(0);
    let firstRun = true;
    
    const isFontLoaded = useFontFaceObserver([
        /*{ family: 'Gotham Condensed Bold' },
        { family: 'Gotham Bold' },*/
        { family: 'Ideal Sans Bold' },
      ]);

    useLayoutEffect(()=>{
        checkSize();
        
    }, [myself.current, myFontSizeLarge, myFontSizeSmall, props.strippedBannerName, props.schoolName,isFontLoaded, heightLimitSmall, heightLimitLarge])



    const checkSize = () => {
        const maxBannerLines = 3;
        if (myself.current && isFontLoaded) {
            let height = adjustHeight();
            if (props.type ==="summary-banner") {
                if (props.strippedBannerName==="Elementary") {
                    setHeightLimitSmall(34);
                }
                if (props.strippedBannerName==="Elementary-Lighthouse") {
                    setHeightLimitSmall(28);
                }
                if (props.strippedBannerName==="Secondary") {
                    setHeightLimitSmall(26);
                }
                if (props.strippedBannerName==="Secondary-Lighthouse") {
                    setHeightLimitSmall(27);
                }
                if (props.strippedBannerName==="Legacy") {
                    setHeightLimitSmall(20);
                }
                if (height >= heightLimitSmall || (height / myFontSizeSmall) > maxBannerLines)  {
                    setMarginTop(0);    
                    setMyFontSizeSmall(myFontSizeSmall - 1);   
                }
                else {
                    let height = adjustHeight();
                    let margin = (heightLimitSmall - (height))/2;
                    console.log("font = "+myFontSizeSmall+", height = "+height+", margin = "+margin);
                    setMarginTop(margin);
                }
            }
            else {
                setMarginTop(0);
                if (props.strippedBannerName=="Legacy") {
                    setHeightLimitLarge(350);
                }
                if (height >= heightLimitLarge || (height / myFontSizeLarge) > maxBannerLines)  {
                    setMarginTop(0); 
                    setMyFontSizeLarge(myFontSizeLarge - 2);     
                    console.log("font = "+myFontSizeLarge);
                    //height = myself.current[heightProperty];
                    //setMyHeight(height); 
                }
                else {
                    let height = adjustHeight();
                    let margin = (heightLimitLarge - (height))/2;
                    console.log("font = "+myFontSizeLarge+", height = "+height+", margin = "+margin);
                    setMarginTop(margin);
                }

            }

    }
    else {
        //alert("not current");
    }
    }

    const adjustHeight = () => {
        if (myself.current) {
            //const heightProperty = "offsetHeight";
            const heightProperty = "clientHeight";
            let height = myself.current[heightProperty];
            setMyHeight(height);
            console.log("height = "+height);
            return height;
        }
        return 0;
    }

    const getStyles = () => {
        if (props.type ==="summary-banner") {
            
            var styles = {
                fontSize:myFontSizeSmall+"px",
                lineHeight:myFontSizeSmall+"px",
                //height: heightLimitSmall,
                marginTop:marginTop,
                FontFace:"Gotham Condensed Bold"
            }
            return styles;
    
        }
        var styles = {
            fontSize:myFontSizeLarge+"px",
            lineHeight:myFontSizeLarge+"px",
            //height: heightLimitLarge,
            marginTop: marginTop,
            FontFace:"Gotham Condensed Bold"
        }
        return styles;
    }
    return (
        

        <div className={"image-banner " +props.type}  >
            <img src={'images/'+props.strippedBannerName.toLowerCase()+'-large.png'}></img>
            <div className={'banner-text-'+props.strippedBannerName} style={getStyles()} ref={myself}>{props.schoolName}</div>
            
            <p className={'year-text-'+props.strippedBannerName}>{props.schoolYear}</p>
            <p className={props.strippedBannerName+"-orderId orderId"}>{props.orderId}</p>
        </div>
    )

}

export default BannerSample