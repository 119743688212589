// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}  

.grid-container {
    height: 300px;
    width: 80%; 
}
  
.search-input {
    margin-bottom: 10px;
    width: 80%; 
    padding: 0.375rem 0.75rem; 
    height: auto; 
    font-size: 1rem; 
    line-height: 1.5; 
    border: 1px solid #ced4da; 
    border-radius: 0.25rem; 
}  
  
.table-section {
    margin-bottom: 5px;
}  
 
.table-title {
    margin-bottom: 5px; 
    text-align: center;
    font-size: 1.25rem; 
    color: #139607;
    font-weight: bold;
}

.btn-edit {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-edit:hover {
    background-color: #0056b3;
  }

  .btn-edit[disabled] {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
  
  .btn-edit:hover:enabled {
    background-color: #0056b3;
  }
  .ag-watermark {
    position: absolute;
    bottom: 20px;
    right: 25px;
    opacity: 0.7;
    transition: opacity 1s ease-out 3s;
    color: #9b9b9b;
    display: none !important;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/grid.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,cAAc;IACd,wBAAwB;EAC1B","sourcesContent":[".grid-wrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n}  \n\n.grid-container {\n    height: 300px;\n    width: 80%; \n}\n  \n.search-input {\n    margin-bottom: 10px;\n    width: 80%; \n    padding: 0.375rem 0.75rem; \n    height: auto; \n    font-size: 1rem; \n    line-height: 1.5; \n    border: 1px solid #ced4da; \n    border-radius: 0.25rem; \n}  \n  \n.table-section {\n    margin-bottom: 5px;\n}  \n \n.table-title {\n    margin-bottom: 5px; \n    text-align: center;\n    font-size: 1.25rem; \n    color: #139607;\n    font-weight: bold;\n}\n\n.btn-edit {\n    padding: 5px 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .btn-edit:hover {\n    background-color: #0056b3;\n  }\n\n  .btn-edit[disabled] {\n    background-color: #d6d6d6;\n    cursor: not-allowed;\n  }\n  \n  .btn-edit:hover:enabled {\n    background-color: #0056b3;\n  }\n  .ag-watermark {\n    position: absolute;\n    bottom: 20px;\n    right: 25px;\n    opacity: 0.7;\n    transition: opacity 1s ease-out 3s;\n    color: #9b9b9b;\n    display: none !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
