import { Route, Routes, useNavigate } from "react-router-dom";
import React from 'react';
import logo from './logo.svg';
import LoginPage from './LoginPage';
import NotApproved from "./NotApproved";
import SelectBanner from "./SelectBanner";
import Order from "./Order"
import Success from "./Success"
import SummaryPage from "./SummaryPage";
import ViewBannerSample from "./ViewBannerSample";
import './App.css';
import OrderList from "./OrderList";




function App() {
  return (
    <Routes>
    {/* Unprotected Routes */}
    <Route path={process.env.REACT_APP_PublicPath +"/"} element={<LoginPage />} />
    <Route path={process.env.REACT_APP_PublicPath +"/login"} element={<LoginPage />} />
    <Route path={process.env.REACT_APP_PublicPath +"/orderlist"} element={<OrderList />} />
    <Route path={process.env.REACT_APP_PublicPath +"/notapproved"} element={<NotApproved />} />
    <Route path={process.env.REACT_APP_PublicPath + "/order"} element={<Order />} />
    <Route path={process.env.REACT_APP_PublicPath +"/selectbanner"} element={<SelectBanner />} />
    <Route path={process.env.REACT_APP_PublicPath +"/summary"} element={<SummaryPage />} />
    <Route path={process.env.REACT_APP_PublicPath +"/success"} element={<Success />} />
    <Route path={process.env.REACT_APP_PublicPath +"/viewbannersample"} element={<ViewBannerSample />} />
    </Routes>
    
  );
}

export default App;

