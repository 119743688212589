import { useNavigate } from "react-router-dom";
import {useState} from "react"
import OrderPageService from "./Services/OrderPageService"

function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const onClickHandler = async () => {
        if (await OrderPageService.Login(email, password)) {
            localStorage.setItem("email",email);
            navigate(process.env.REACT_APP_PublicPath +"/order", {replace:true});
            //navigate(process.env.REACT_APP_PublicPath +"/orderlist", {replace:true});
        }
        else {
            alert("Invalid username or password");
        }
    }

    return (
        <div className="card">
            <div className="left-component  center relative w-32">
                <img src={require('./images/xceed-banner-landing.jpg')} className="landing-banner" ></img>
            </div>
            <div className="right-component relative w-32">
                <img src={require('./logo.png')} width="200" className="center"></img>
                <div className="container ">
                
                    Welcome to the Xceed Solutions ordering platform.<br></br>
                    <br></br>
                    <span className="bold">Username</span>
                    <br></br>
                    <input 
                    placeholder="Enter your Username" 
                    className="front-page-input"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    /><br></br>
                    <span className="bold">Password</span>
                    <input 
                    placeholder="Password" 
                    className="front-page-input"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    <br></br>
                    <br></br>
                    <button className="bottom-3 right-2 h-12 w-40 " onClick={() =>onClickHandler()}>Login</button>
                </div>
            </div>
      </div>
    );
  }
  
  export default LoginPage;
  