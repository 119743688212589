import { useNavigate } from "react-router-dom";
import TopBanner from "./TopBanner";
function Success() {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate("/order", {replace:true} );
    }
    return (
        <div>
            <TopBanner></TopBanner>
            <div className="card relative h-32 w-32">
                <div className="center">
                    <img src="images/checkmark.png" className="center"></img>
                    <div className="widget-title">Thank You!</div>
          
                    <div className="summary-label center">Your order went through successfully!</div>
                    <br></br>
                    <div className="center-button">
                        <button className="h-12 w-40 center-button" onClick={() =>onClickHandler()}>Order Again</button>
                    </div>
                </div>
            </div>
      </div>
    );
  }
  
  export default Success;