import { useNavigate, useSearchParams } from "react-router-dom";
import {useState, useEffect} from "react"
import OrderPageService from "./Services/OrderPageService"
import { OrderData } from "./Classes/OrderData";
import TopBanner from "./TopBanner";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
function Order() {
    const bannerOptions = [
        'Elementary', 'Elementary - Lighthouse', 'Secondary','Secondary - Lighthouse', 'Legacy'
      ];
      const stateOptions = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL'
        , 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ'
        , 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA'
        , 'WA', 'WV', 'WI', 'WY'];
    const [searchParams, setSearchParams] = useSearchParams();
    const countryOptions = ['United States', 'Canada'];
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [attention, setAttention] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [schoolYear, setSchoolYear] = useState("");
    const [bannerName, setBannerName] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [editOrderId, setEditOrderId] = useState("");
    const [userName, setUserName] = useState("")
    const [isCountryUS, setisCountryUS] = useState(true)
    const navigate = useNavigate();

    useEffect(() => {
        onLoad();
    }, [])

    const onLoad = async () => {
        let orderId = searchParams.get("orderid");
        if (orderId != null){
            setEditOrderId(orderId);
            let userEmail = localStorage.getItem("email") || "";
            if (userEmail != null) {
                let defaultData : OrderData = await OrderPageService.getOrderInfo(userEmail);
                    setEmail(defaultData.email)
                    setAttention(defaultData.attention);
                    if (defaultData.country == "CA") {
                        countryHandler("Canada");
                    }
                    else {
                        countryHandler("United States");
                    }
                    setAddress1(defaultData.address1);
                    setCity(defaultData.city);
                    setZip(defaultData.zip);
                    setState(defaultData.state);
                    setSchoolName(defaultData.schoolName);
                    setBannerName(defaultData.banner);
                    setSchoolYear(defaultData.schoolYear);
                    setPhone(defaultData.phone);
            }
        }
            
        let localuserName = localStorage.getItem("username");
        let token = localStorage.getItem("token");

        console.log(localuserName,"User name");
        console.log(token, 'Token');
        
        if (localuserName != null && token != null) {
            let apiUsername= await OrderPageService.getUsernameByToken(token);
            if (localuserName == apiUsername) {
                setUserName(apiUsername);
            }
            else {
                console.log(apiUsername);
                navigate(process.env.REACT_APP_PublicPath +"/login", {replace:true} );
            }
            
        }
        else {
            navigate(process.env.REACT_APP_PublicPath +"/login", {replace:true} );
        }
        
    }

    const isNullOrEmpty = (item:string) => {
        if (item===null||item===""||item===undefined) {
            return true;
        }
        return false;
    }

    const countryHandler = async (myCountry:string) => {
        setCountry(myCountry);
        if (myCountry == "Canada") {
            setisCountryUS(false);
        }
        else {
            setisCountryUS(true);
        }
    }

    const onClickHandler = async () => {
        if (isNullOrEmpty(email)||isNullOrEmpty(attention)||isNullOrEmpty(address1)||isNullOrEmpty(city)
            ||isNullOrEmpty(state)||isNullOrEmpty(zip)||isNullOrEmpty(schoolName)||isNullOrEmpty(schoolYear)||isNullOrEmpty(bannerName)||isNullOrEmpty(country)) {
            alert("Please fill out all fields");
        }
        else {
            let submitEmail = email.trim();
            let submitState = state.trim();
            let postData : OrderData= {
                email: submitEmail,
                attention: attention, 
                address1: address1,
                city: city,
                state: submitState,
                zip: zip,
                schoolName: schoolName,
                schoolYear: schoolYear,
                banner: bannerName,
                bannerOrderId:editOrderId,
                orderDate:"",
                country:country=="United States"?"US":"CA",
                phone: phone.trim(),
                username:userName,
                orderGroup:"",
            }
            if (await OrderPageService.submitOrder(postData)) {
                navigate(process.env.REACT_APP_PublicPath +"/summary", {replace:false} );
            }
            else {
                alert("There was a problem submitting your order.  Please try again later.")
            }
        }
    }
    
    return (
        <div>
            <TopBanner></TopBanner>
            <div className="small-card relative w-32">
                <div className="container ">
          
                    
                    <br></br>
                    <div className="widget-title center">ORDER INFORMATION</div>
                    <div className="widget-title">Your Information</div>
                    <input placeholder="CEC Email" className="w-5/12 mb-4" type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <br></br>
                    <div className="widget-title">Banner Information</div>
                    <input placeholder="Enter School Name" className="w-5/12 mb-4" type="text" value={schoolName} onChange={(e) => setSchoolName(e.target.value)}/> 
                    <input placeholder="Enter School Year (YYYY-YYYY)" className="w-5/12 mb-4 float-right" type="text" value={schoolYear} onChange={(e) => setSchoolYear(e.target.value)}/> 
                    <Dropdown options={bannerOptions} className="w-5/12 mb-4" onChange={(e) => setBannerName(e.value)} value={bannerName} placeholder="Banner Template" /><br></br>
                    <div className="widget-title">Shipping Information</div>
                    
                    <input placeholder="Attention" className="w-full mb-4" type="text" value={attention} onChange={(e) => setAttention(e.target.value)}/><br></br>
                    <input placeholder="Street Address" className="w-full mb-4" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)}/> <br></br>
                    <input placeholder="City" className="w-5/12 mb-4" type="text" value={city} onChange={(e) => setCity(e.target.value)}/> 
                    <span hidden={isCountryUS}>
                    <input placeholder="State/Province" className="w-5/12 float-right mb-4" type="text" value={state} onChange={(e) => setState(e.target.value)}/><br></br>
                    </span>
                    <span hidden={!isCountryUS}>
                    <Dropdown options={stateOptions} className="w-5/12 float-right" onChange={(e) => setState(e.value)} value={state} placeholder="State" />
                    </span>
                    <input placeholder="Zip/Postal Code" className="mb-4 w-5/12" type="text" value={zip} onChange={(e) => setZip(e.target.value)}/> 
                    <Dropdown options={countryOptions} className="w-5/12 mb-4 float-right padding-0" onChange={(e) => countryHandler(e.value)} value={country} placeholder="Country" /><br></br>
                    <input placeholder="Phone" className="mb-4 w-5/12" type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    <br></br>
                    <br></br>
                    

                    
                </div>
                <button className="absolute bottom-3 right-2 h-12 w-40" onClick={() =>onClickHandler()}>Review Order</button>
            </div>
      </div>
    );
  }
  
  export default Order;