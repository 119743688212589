import React, { useState } from 'react';
//import './Styles/deleteButton.css';
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline'
const ViewButtonRenderer = (props: any) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setShowModal(true); 
  };

  return (    
    <DocumentMagnifyingGlassIcon onClick={handleClick} className="size-6 text-green-500 cursor-pointer" />
  );
};

export default ViewButtonRenderer;