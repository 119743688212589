import { useNavigate } from "react-router-dom";
import {useState, useEffect, useRef} from "react"
import TopBanner from "./TopBanner";
import OrderPageService from "./Services/OrderPageService"
import { OrderData } from "./Classes/OrderData";
import BannerSample from "./BannerSample";
import * as htmlToImage from 'html-to-image';
import { ColorRing } from "react-loader-spinner"

function SummaryPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [orderId, setOrderId] = useState("");
    const [bannerName, setBannerName] = useState("");
    const [schoolYear, setSchoolYear] = useState("");
    const [attention, setAttention]= useState("");
    const [country, setCountry]= useState("");
    const [strippedBannerName, setStrippedBannerName]= useState("");
    const [isLoading, setIsLoading]= useState(false);
    const [mode, setMode]=useState(false);
    const [localMode, setLocalMode]= useState("");
    
    useEffect(() => {
        onLoad();
    }, [])

    const onLoad = async () => {
        let summaryMode = localStorage.getItem("mode")||"";
        setLocalMode(summaryMode);
        console.log(mode);
        if(summaryMode ==="view"){
            setMode(false);
        }else{
            setMode(true);
        }
        
        let userEmail = localStorage.getItem("email") || "";
        if (userEmail != null) {
            let defaultData : OrderData = await OrderPageService.getOrderInfo(userEmail);
                setEmail(defaultData.email)
                setAttention(defaultData.attention);
                setCountry(defaultData.country);
                setAddress1(defaultData.address1);
                setCity(defaultData.city);
                setZip(defaultData.zip);
                setState(defaultData.state);
                setSchoolName(defaultData.schoolName);
                setOrderDate(defaultData.orderDate);
                setOrderId(defaultData.bannerOrderId);
                setBannerName(defaultData.banner);
                setSchoolYear(defaultData.schoolYear);
                setPhone(defaultData.phone);
                setStrippedBannerName(defaultData.banner.replaceAll(' ',''));
               
                    
                
        }
        
    }

    
    const confirmHandler = async () => {
        setIsLoading(true);
            if (await OrderPageService.confirmOrder(orderId,localMode)) {
                navigate(process.env.REACT_APP_PublicPath +"/success", {replace:true} );
            }
            else {
                setIsLoading(false);
                alert("There was a problem confirming your order.  Please try again later.")
            }
    }

    const editHandler = async () => {
            navigate(process.env.REACT_APP_PublicPath +"/order?orderid="+orderId, {replace:true} );
            
    }




    return (
        <div>
            <TopBanner></TopBanner> 

            <div className="no-shadow" hidden={isLoading}>
                <div className="widget-title">Order Summary</div>   
                <div className="grid grid-cols-4 ">
                    <div>
                    <BannerSample type="summary-banner" bannerName={strippedBannerName} strippedBannerName={strippedBannerName} schoolName={schoolName} schoolYear={schoolYear}/>
                        </div>
                        <div>
                            <div className="summary-item">{bannerName}</div>
                            <br></br>
                            <div className="grid grid-cols-2">
                                <div className="summary-label">Order ID:</div>
                                <div className="summary-item">{orderId}</div>
                                <div className="summary-label">Placed On:</div>
                                <div className="summary-item">{orderDate}</div>
                            </div>
                        </div>
                </div>
                <br></br>
                <hr></hr>
                <div className="widget-title">Details</div>   
                <div className="grid grid-cols-2 ">
                    <div className="grid grid-cols-2 ">
                        <div className="summary-label">Contact Name:</div>
                        <div className="summary-item">{attention}</div>
                        <div className="summary-label">School Name:</div>
                        <div className="summary-item">{schoolName}</div>
                        <div className="summary-label">School Year:</div>
                        <div className="summary-item">{schoolYear}</div>
                        <div className="summary-label">Email:</div>
                        <div className="summary-item">{email}</div>
                        <div className="summary-label">Phone:</div>
                        <div className="summary-item">{phone}</div>
                    </div>
                    <div className="grid grid-cols-2 ">
                        <div className="summary-label">Address:</div>
                        <div className="summary-item">{address1}</div>
                        <div className="summary-label">City:</div>
                        <div className="summary-item">{city}</div>
                        <div className="summary-label">State:</div>
                        <div className="summary-item">{state}</div>
                        <div className="summary-label">Zip Code:</div>
                        <div className="summary-item">{zip}</div>
                        <div className="summary-label">Country:</div>
                        <div className="summary-item">{country}</div>

                    </div>
                </div>
                <br></br>
                
                <hr></hr>
                <br></br>

                
            </div>
            {!isLoading && mode &&
            <div className="center-button" hidden={isLoading}>
                
                    <button className="h-12 w-40" onClick={() =>editHandler()}>Edit Order</button>
                    &nbsp;
                    <button className="h-12 w-40" onClick={() =>confirmHandler()}>Confirm Order</button>
            </div>
            }
            {
                isLoading && 
                <div className="loading-center" hidden={!isLoading}>
                <ColorRing
                    visible={isLoading}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#8dc73f', '#8dc73f', '#8dc73f','#8dc73f', '#8dc73f']} />
            </div>
            }

      </div>
    );
  }
  
  export default SummaryPage;