import { useNavigate } from "react-router-dom";
function NotApproved() {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate("/", {replace:true} );
    }
    return (
        <div>
            <img src={require('./logo.png')}></img>
            <div className="card relative w-32">
                <div className="container">
          
                    Our apologies!  It seems that your email address has not been approved for ordering on this site.<br></br>
                    <button className="absolute bottom-3 right-2 h-22 w-32" onClick={() =>onClickHandler()}>Try Again</button>
                </div>
            </div>
      </div>
    );
  }
  
  export default NotApproved;
  