
import { useSearchParams } from "react-router-dom";
import BannerSample from "./BannerSample"
const ViewBannerSample = (props: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
        return (

        <div>
            <BannerSample type="print-banner" schoolName={searchParams.get("schoolname")} schoolYear={searchParams.get("schoolyear")} strippedBannerName={searchParams.get("banner")} orderId={searchParams.get("orderId")}/>
        </div>
    )

}

export default ViewBannerSample