import axios, { AxiosRequestConfig } from "axios";
 
const API_URL = process.env.REACT_APP_API_URL;  
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  }
});

instance.interceptors.response.use(
  (res) => {
    console.log(res); 
    return res;
  }
);

export default instance;