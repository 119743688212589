import { useNavigate } from "react-router-dom";
import {useState, useEffect} from "react"
import BannerOption from './BannerOption'
import TopBanner from "./TopBanner";
function SelectBanner() {
    const navigate = useNavigate();

    useEffect(() => {
        onLoad();
    }, [])

    const onLoad = async () => {
        let userEmail = localStorage.getItem("email");
       
    }

    const isNullOrEmpty = (item:string) => {
        if (item===null||item===""||item===undefined) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <TopBanner></TopBanner> 
            <div className="widget-title center">Please Select You Desired Banner</div>   
            <div className="grid grid-cols-3 ">
                    <BannerOption text="Elementary Banner" imgPath={require("./images/elementary-banner.png")}></BannerOption>
                    <BannerOption text="Elementary Lighthouse Banner" imgPath={require("./images/elementary-lighthouse-banner.png")}></BannerOption>
                    <BannerOption text="Elementary Lighthouse w/ Academic Distinction Banner" imgPath={require("./images/elementary-lighthouse-w-academic-distinction-banner.png")}></BannerOption>
                    <BannerOption text="K-8/Middle/High Banner" imgPath={require("./images/k8-middle-high-banner.png")}></BannerOption>
                    <BannerOption text="K-8/Middle/High Lighthouse Banner" imgPath={require("./images/k8-middle-high-lighthouse-banner.png")}></BannerOption>
                    <BannerOption text="Elementary Lighthouse (w/Academic Distinction) Banner" imgPath={require("./images/elementary-lighthouse-w-academic-distinction-banner.png")}></BannerOption>
                    <BannerOption text="K-12 Legacy Banner" imgPath={require("./images/k-12-legacy-banner.png")}></BannerOption>
                    <BannerOption text="K-8/Middle/High Lighthouse (w/Academic Distinction) Banner" imgPath={require("./images/k-8-middle-high-lighthouse-w-adademic-distinction-banner.png")}></BannerOption>
            </div>

      </div>
    );
  }
  
  export default SelectBanner;