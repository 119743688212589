import { useNavigate } from "react-router-dom";
import {useState, useEffect} from "react"
import OrderPageService from "./Services/OrderPageService"

function BannerOption(props:any) {

    const navigate = useNavigate();

    useEffect(() => {
        onLoad();
    }, [])

    const onLoad = async () => {
        let userEmail = localStorage.getItem("email");
        
    }

    const isNullOrEmpty = (item:string) => {
        if (item===null||item===""||item===undefined) {
            return true;
        }
        return false;
    }

    const onClickHandler = async () => {
        if (await OrderPageService.submitBannerChoice(props.text)) {
            navigate(process.env.REACT_APP_PublicPath +"/summary", {replace:true} );
        }
    }
    return (
        <div className='selection-card w-32'>
            <img src={props.imgPath} onClick={() =>onClickHandler()} className='center'></img>
            <div className='center'>{props.text}</div>
      </div>
    );
  }
  
  export default BannerOption;