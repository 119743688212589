import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import EditButtonRenderer from './EditButtonRenderer';
import DeleteButtonRenderer from './DeleteButtonRenderer';
import ViewButtonRenderer from './ViewButtonRenderer';
import './Styles/grid.css';

interface DataGridProps {
  rowData: any[];
  columns: any[];
  getOnclickCellData: (event: any) => void;
  orderType: string;
}

const DataGrid: React.FC<DataGridProps> = (props) => {
  const { rowData, columns, orderType, getOnclickCellData } = props;
  const [gridApi, setGridApi] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (gridApi) {
      gridApi.setColumnDefs(getColumnsWithActions());
    }
  }, [orderType]);

  const fetchData = (params: any) => {
    setGridApi(params.api);
  };

  const searchFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    if (gridApi) {
      gridApi.setQuickFilter(e.target.value);
    }
  };

  const defaultColDef = {
    sortable: true,
    editable: false,
    flex: 1,
    resizable: true,
    suppressMenu: true
  };

  const getColumnsWithActions = () => {
    const baseColumns = [...columns];
    baseColumns.push(
      {
        headerName: '',
        field: 'view',
        cellRenderer: ViewButtonRenderer,
        filter: false,
        sortable: false,
        width: 60,
        minWidth: 60,
        maxWidth: 70,
        suppressMenu: true,
        cellStyle: { textAlign: 'center' },
      }
    );
    return baseColumns;
  };

  return (
    <div className="grid-wrapper">
      {/* <input
        type="search"
        className="form-control search-input mb-4"
        placeholder="Search orders..."
        aria-label="Search orders"
        value={searchQuery}
        onChange={searchFunction}
      /> */}
      <div className="ag-theme-alpine grid-container">
        <AgGridReact
          rowData={rowData}
          onGridReady={fetchData}
          defaultColDef={defaultColDef}
          columnDefs={getColumnsWithActions()}
          pagination={true}
          paginationPageSize={10}
          onCellClicked={getOnclickCellData}
          paginationPageSizeSelector={[10, 20, 50, 100]}
        />
      </div>
    </div>
  );
};

export default DataGrid;
